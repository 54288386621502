import React, { PureComponent } from "react";

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center", paddingBottom: "2%" }}>
          Designed and Built by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.linclogix.com/"
          >
            LincLogix LLC
          </a>
          <br />
          Is something broken?{" "}
          <a href="mailto:hbromund@linclogix.com">Submit a Bug Report</a>
          <br />
          Hosted on Google App Engine | Version:{" "}
          <i>{process.env.REACT_APP_VERSION}</i>
        </div>
      </div>
    );
  }
}

export default Footer;
