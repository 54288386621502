import React, { PureComponent } from "react";
import Autocomplete from "react-google-autocomplete";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import classes from "./SearchBar.module.css";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Results from "../Results/Results";
import DownloadReport from "../DownloadReport/DownloadReport";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      streetSearchHidden: false,
      coordinateSearchHidden: true,
      place: null,
      lat: null,
      lon: null,
      zip: null,
      streetAddress: null,
      formatted_address: "",
      tempLat: null,
      tempLon: null,
    };

    this.handleLonChange = this.handleLonChange.bind(this);
    this.handleLatChange = this.handleLatChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      lat: this.state.tempLat,
      lon: this.state.tempLon,
      formatted_address: "",
    });
  }

  handleLatChange(event) {
    // tempLat is used so that the Collapse that is hiding the map doesn't open before
    // all of the coordinates have been entered
    this.setState({ tempLat: event.target.value });
  }

  handleLonChange(event) {
    // tempLon is used so that the Collaps that is hiding the map doesn't open
    // before all of the the coordinates have been entered
    this.setState({ tempLon: event.target.value });
  }

  render() {
    return (
      <div className={classes.inner}>
        <div>
          <ButtonGroup>
            <Button
              variant={
                this.state.streetSearchHidden ? "outline-primary" : "primary"
              }
              onClick={() =>
                this.setState({
                  streetSearchHidden: !this.state.streetSearchHidden,
                  coordinateSearchHidden: true,
                })
              }
            >
              Search By Street Address
            </Button>

            <Button
              variant={
                this.state.coordinateSearchHidden
                  ? "outline-primary"
                  : "primary"
              }
              onClick={() =>
                this.setState({
                  coordinateSearchHidden: !this.state.coordinateSearchHidden,
                  streetSearchHidden: true,
                })
              }
            >
              Search By Coordinates
            </Button>
          </ButtonGroup>
          <div className={classes.coordinateSearch}>
            <Collapse in={!this.state.coordinateSearchHidden}>
              <div>
                <h4>Search by Coordinates</h4>
                <p>
                  Enter the exact Coordiantes of the location using Decimal
                  Degrees Format. All values must be between -90 and 90.
                </p>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        step="any"
                        placeholder="Latitude (ex. 13.3847)"
                        onChange={this.handleLatChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        step="any"
                        placeholder="Longitude (ex. -80.3289)"
                        onChange={this.handleLonChange}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Button variant="primary" type="submit">
                    Search
                  </Button>
                </Form>
              </div>
            </Collapse>
          </div>
          <div>
            <Collapse in={!this.state.streetSearchHidden}>
              <div className={classes.addressSearch}>
                <h4>Search by Address</h4>
                <p>
                  Enter a Street Address and then select the street address you
                  wany from the list provided.
                </p>
                <Autocomplete
                  style={{
                    width: "100%",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onPlaceSelected={(place) => {
                    // console.log(place.address_components);
                    var tempZip;
                    if (place.address_components[7] === undefined) {
                      tempZip = null;
                    } else {
                      tempZip = place.address_components[7].long_name;
                    }

                    this.setState({
                      place: place,
                      lat: place.geometry.location.lat(),
                      lon: place.geometry.location.lng(),
                      zip: tempZip,
                      formatted_address: place.formatted_address,
                      streetAddress: place.formatted_address.split(",", 1)[0],
                    });
                  }}
                  types={["address"]}
                  componentRestrictions={{ country: "us" }}
                  fields={[
                    "geometry",
                    "address_components",
                    "formatted_address",
                  ]}
                />
              </div>
            </Collapse>
          </div>
        </div>
        <Collapse in={this.state.lat != null}>
          <Row xs={1} md={2} lg={2} xl={2} sm={1}>
            <Col>
              <div className={classes.mapDiv}>
                <Map
                  google={this.props.google}
                  zoom={14}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                  }}
                  center={{ lat: this.state.lat, lng: this.state.lon }}
                >
                  <Marker
                    position={{ lat: this.state.lat, lng: this.state.lon }}
                  />
                </Map>
              </div>
            </Col>
            <Col>
              <Card bg="dark">
                <Card.Body>
                  <Card.Title>Searching For:</Card.Title>
                  <hr />
                  <Card.Text>
                    <b>{this.state.formatted_address}</b>
                  </Card.Text>
                  <Card.Text>
                    <b>Latitude:</b> {this.state.lat}
                  </Card.Text>
                  <Card.Text>
                    <b>Longitude:</b> {this.state.lon}
                  </Card.Text>
                  <Card.Text>
                    <b>Zip:</b> {this.state.zip}
                  </Card.Text>
                </Card.Body>
              </Card>
              <br />
              <Card bg="dark">
                <Card.Body>
                  <Card.Title>
                    Download Report <Badge variant="warning">BETA</Badge>
                  </Card.Title>
                  <hr />
                  <DownloadReport lat={this.state.lat} lon={this.state.lon} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Collapse>
        <br />
        <div>
          <Results
            lat={this.state.lat}
            lon={this.state.lon}
            zip={this.state.zip}
            streetAddress={this.state.streetAddress}
          />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBs11HY0ckcBEr9GuvWtEgwgqQpfHO6Sys",
})(SearchBar);
