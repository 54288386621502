export var BASE = "https://backend-dot-odin-linclogix.uk.r.appspot.com";
if (process.env.NODE_ENV === "development") {
  BASE = "http://localhost:5000";
}

export const GET_TMOBILE_SERVICE = BASE + "/tmobileService";
export const GET_ATT_SERVICE = BASE + "/attService";
export const GET_BROADBAND_SERVICE = BASE + "/broadbandService";
export const GET_BACKEND_STATUS = BASE + "/hello";
export const GET_ON_NET_LOCATIONS = BASE + "/onNetLocation";
