import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import logo from "../../assets/logo_BW.png";

import { GET_BROADBAND_SERVICE } from "../../constants/Constants";

const styles = StyleSheet.create({
  page: { padding: "10px" },
  header: { margin: "auto" },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row", fontSize: 10 },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: 5 },
  tableHeading: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 10,
  },
  image: {
    maxWidth: "230px",
    maxHeight: "95px",
    width: "auto",
    height: "auto",
  },
});

class DownloadReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { serviceArray: null };
    this.getBroadbandService = this.getBroadbandService.bind(this);
    this.generateWiredCarriersPDF = this.generateWiredCarriersPDF.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({ serviceArray: null });
      if (this.props.lat != null && this.props.lon != null) {
        var serviceArray = await this.getBroadbandService(
          this.props.lat,
          this.props.lon
        );
        this.setState({ serviceArray: serviceArray });
        // console.log(serviceArray);
      }
    }
  }

  async getBroadbandService(lat, lon) {
    var request = GET_BROADBAND_SERVICE + "/" + lat + "/" + lon;

    var response = await fetch(request, { method: "GET" }).catch((err) => {
      console.error(err);
    });

    var body = await response.json();
    // console.log(body);
    return body;
    // this.setState({ broadbandService: body });
  }

  generateWiredCarriersPDF() {
    var output = [];
    const serviceArray = this.state.serviceArray;

    for (let i = 0; i < serviceArray.length; i++) {
      var technology = "";

      switch (serviceArray[i].techcode) {
        case "10":
          technology = "Asymetrical xDSL";
          break;
        case "11":
          technology = "ADSL2";
          break;
        case "12":
          technology = "VDSL";
          break;
        case "20":
          technology = "Symetrical xDSL";
          break;
        case "30":
          technology = "Other Copper Wireline";
          break;
        case "40":
          technology = "Cable Modem";
          break;
        case "41":
          technology = "Cable Modem-DOCSIS1,1.1, and 2.0";
          break;
        case "42":
          technology = "Cable Modem-DOCSIS 3.0";
          break;
        case "43":
          technology = "Cable Modem-DOCSIS 3.1";
          break;
        case "50":
          technology = "Optical Carrier/Fiber to the End User";
          break;
        case "60":
          technology = "Satellite";
          break;
        case "70":
          technology = "Terrestrial Fixed Wireless";
          break;
        case "90":
          technology = "Electric Power Line";
          break;
        default:
          technology = "Other - " + serviceArray[i].techcode;
      }

      var downSpeed = "";
      var upSpeed = "";
      // var certified = false;
      if (serviceArray[i].maxcirdown === "0") {
        downSpeed = serviceArray[i].maxaddown;
      } else {
        downSpeed = serviceArray[i].maxcirdown;
        // certified = true;
      }
      if (serviceArray[i].maxcirup === "0") {
        upSpeed = serviceArray[i].maxadup;
      } else {
        upSpeed = serviceArray[i].maxcirup;
        // certified = true;
      }

      output.push(
        <View key={serviceArray[i].dbaname + "-" + i} style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{serviceArray[i].dbaname}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{serviceArray[i].providername}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{technology}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{downSpeed}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{upSpeed}</Text>
          </View>
        </View>
      );
    }
    return output;
  }
  render() {
    if (this.state.serviceArray !== null) {
      var pdf = [
        <Document
          title="Internet Availability"
          author="Odin"
          creator="Odin"
          producer="LincLogix"
        >
          <Page size="LETTER" style={styles.page}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Image style={styles.image} src={logo}></Image>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Image style={styles.image} src={logo}></Image>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.header}>
              <Text>Wired Carriers</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeading}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>NAME</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>PROVIDER</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>TECHNOLOGY</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>MAX DOWNLOAD</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>MAX UPLOAD</Text>
                </View>
              </View>
              {this.generateWiredCarriersPDF()}
            </View>
          </Page>
        </Document>,
      ];
    }
    return (
      <div>
        <PDFDownloadLink document={pdf} fileName="InternetAvailability.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "PDF"
          }
        </PDFDownloadLink>
        &nbsp;
        <Button hidden>CSV (Coming Soon)</Button>
        &nbsp;
      </div>
    );
  }
}

export default DownloadReport;
