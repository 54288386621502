import React, { PureComponent } from "react";
import Collapse from "react-bootstrap/Collapse";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import {
  GET_TMOBILE_SERVICE,
  GET_ATT_SERVICE,
} from "../../constants/Constants";

/*
 * ATT backend that will give coverage at a Lat/Lon Coordinate Pair
 * https://onemap.att.com/OneMapWS/Home/GetBestCoverage?latitude=39.93927&longitude=-86.21779
 *
 *
 *
 */

class WirelessCarriers extends PureComponent {
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    this.setState({
      lat: nextProps.lat,
      lon: nextProps.lon,
    });
    if (nextProps.lat != null && nextProps.lon != null) {
      this.getTmobileService(nextProps.lat, nextProps.lon);
      this.getATTService(nextProps.lat, nextProps.lon);
    }
  }
  constructor(props) {
    super(props);

    this.state = {
      lat: props.lat,
      lon: props.lon,
      tmobileService: null,
      attService: null,
    };
    this.getTmobileService = this.getTmobileService.bind(this);
    this.getATTService = this.getATTService.bind(this);
  }

  getTmobileService = async (lat, lon) => {
    // console.log(this.state);
    var request = GET_TMOBILE_SERVICE + "/" + lat + "/" + lon;
    // console.log(request);
    var response = await fetch(request, {
      method: "GET",
    }).catch((err) => {
      console.error(err);
    });
    // console.log(await response.json);

    var body = await response.json();
    // console.log(body);
    this.setState({ tmobileService: body[0] });
  };

  getATTService = async (lat, lon) => {
    // console.log(this.state);
    var request = GET_ATT_SERVICE + "/" + lat + "/" + lon;
    // console.log(request);
    var response = await fetch(request, {
      method: "GET",
    }).catch((err) => {
      console.error(err);
    });
    // console.log(await response.json);
    // console.log(response);
    var body = await response.json();
    // console.log(body);
    this.setState({ attService: body });
  };

  render() {
    return (
      <div>
        <h2>
          Wireless Carriers
          <div style={{ float: "right" }}>
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href="https://members.wispa.org/members/directory/search_bootstrap.php?org_id=WISP"
              variant="primary"
            >
              Fixed Wireless Carriers
            </Button>
          </div>
        </h2>

        <i>
          Wireless Carrier data is pulled from the major wireless carrier
          websites.
        </i>
        <hr />
        <Collapse in={this.state.lat != null}>
          <div>
            <CardDeck>
              <Card bg="dark">
                <Card.Body>
                  <Card.Title>AT&T</Card.Title>
                  <hr />
                  <Card.Text>
                    <b>Service: </b>
                    {this.state.attService
                      ? this.state.attService.coverageString
                      : ""}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.att.com/maps/wireless-coverage.html"
                    variant="primary"
                  >
                    View AT&T Coverage Map
                  </Button>
                </Card.Footer>
              </Card>
              <Card bg="dark">
                <Card.Body>
                  <Card.Title>T-Mobile</Card.Title>
                  <hr />
                  <Card.Text>
                    <b>Service: </b>
                    {this.state.tmobileService
                      ? this.state.tmobileService.type
                      : ""}{" "}
                    <Badge
                      hidden={
                        !(
                          this.state.tmobileService != null &&
                          this.state.tmobileService.verified === true
                        )
                      }
                      variant="success"
                    >
                      Verified
                    </Badge>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.t-mobile.com/coverage/coverage-map"
                    variant="primary"
                  >
                    View T-Mobile Coverage Map
                  </Button>
                </Card.Footer>
              </Card>
              <Card bg="dark">
                <Card.Body>
                  <Card.Title>Verizon</Card.Title>
                  <hr />
                  <Card.Text>
                    <b>Service: </b> <i>No Data Available</i>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.verizon.com/featured/our-network/#maps"
                    variant="primary"
                  >
                    View Verizon Coverage Map
                  </Button>
                </Card.Footer>
              </Card>
            </CardDeck>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default WirelessCarriers;
