import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

import classes from "./OnNetLocationsCard.module.css";

// Carrier
// Access Type
// Building Connectivity Status
// Last Updated
// Street Address
// State
// Zip
// City
export default class OnNetLocationsCard extends Component {
  render() {
    return (
      <Card bg="dark" className={classes.OnNetLocationsCard}>
        <Card.Body>
          <Card.Title>
            <b>{this.props.carrier}</b>
          </Card.Title>
          <Card.Subtitle>
            <Badge
              variant={
                this.props.connectivityStatus === "OnNet"
                  ? "primary"
                  : "secondary"
              }
            >
              {this.props.connectivityStatus}
            </Badge>{" "}
            | <i>Last Updated in {this.props.lastUpdated}</i>
          </Card.Subtitle>
          <b>Access Type: </b> {this.props.accessType}
          <br />
          <b>Address: </b> {this.props.street}, {this.props.city},{" "}
          {this.props.state}, {this.props.zip}
        </Card.Body>
      </Card>
    );
  }
}
