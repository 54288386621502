import React, { PureComponent } from "react";

import classes from "./Results.module.css";

import WiredCarriers from "../WiredCarriers/WiredCarriers";
import WirelessCarriers from "../WirelessCarriers/WirelessCarriers";
import OnNetLocations from "../OnNetLocations/OnNetLocations";

class Results extends PureComponent {
  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps);

  //   this.setState({ lat: nextProps.lat, lon: nextProps.lon });
  // }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={classes.wrapper}>
        <div>
          <OnNetLocations
            lat={this.props.lat}
            lon={this.props.lon}
            zip={this.props.zip}
            streetAddress={this.props.streetAddress}
          />
        </div>
        <div>
          <WiredCarriers lat={this.props.lat} lon={this.props.lon} />
        </div>
        <div>
          <WirelessCarriers lat={this.props.lat} lon={this.props.lon} />
        </div>
      </div>
    );
  }
}

export default Results;
