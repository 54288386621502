import React, { PureComponent } from "react";

import classes from "./Title.module.css";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import { GET_BACKEND_STATUS } from "../../constants/Constants";

class Title extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      backendStatus: "loading",
    };
  }
  componentDidMount = async () => {
    var response = await fetch(GET_BACKEND_STATUS, { method: "GET" }).catch(
      (err) => {
        this.setState({ backendStatus: "offline" });
        return;
      }
    );
    if (response === undefined) {
      this.setState({ backendStatus: "offline" });
    } else if (response.status === 200) {
      this.setState({ backendStatus: "online" });
    } else {
      this.setState({ backendStatus: "offline" });
    }
  };

  render() {
    var backendText = [];
    var backendVariant;
    if (this.state.backendStatus === "loading") {
      backendText.push(<Spinner size="sm" animation="grow"></Spinner>);
      backendVariant = "warning";
    } else if (this.state.backendStatus === "online") {
      backendText.push("Online");
      backendVariant = "success";
    } else if (this.state.backendStatus === "offline") {
      backendText.push("Offline");
      backendVariant = "danger";
    }
    return (
      <div className={classes.background}>
        <div className={classes.wrapper}>
          <h1>
            <strong>ODIN</strong>
          </h1>
          <p>
            <b>Status: </b>
            <Badge variant={backendVariant}>{backendText}</Badge>
          </p>
          <Alert hidden variant="warning">
            <Alert.Heading>ODIN is an internal tool</Alert.Heading>
            <p>
              All data found on this website is for the internal use of
              LincLogix and its employees only.
            </p>
          </Alert>
        </div>
      </div>
    );
  }
}

export default Title;
