import React, { Component } from "react";
import { GET_ON_NET_LOCATIONS } from "../../constants/Constants";

import OnNetLocationsCard from "./OnNetLocationsCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default class OnNetLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "",
      exactMatch: [],
      streetMatch: [],
      zipMatches: 0,
    };
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // console.log(this.props);
    if (this.props !== prevProps) {
      this.setState({ loading: "Loading...", zipMatches: 0 });
      if (this.props.zip != null) {
        var res = await fetch(GET_ON_NET_LOCATIONS + "/" + this.props.zip);
        var body = await res.json();
        // console.log(body);

        var exactMatch = [];
        var streetMatch = [];
        var street = this.props.streetAddress.substr(
          this.props.streetAddress.indexOf(" ") + 1
        );
        // console.log(street);
        for (var i = 0; i < body.length; i++) {
          var location = body[i];
          // console.log(
          //   location["Street Address"].toUpperCase() +
          //     " - " +
          //     this.props.streetAddress.toUpperCase()
          // );
          if (
            location["Street Address"].toUpperCase() ===
            this.props.streetAddress.toUpperCase()
          ) {
            exactMatch.push(location);
          }
          var locationStreet = location["Street Address"].substr(
            location["Street Address"].indexOf(" ") + 1
          );

          if (locationStreet.toUpperCase() === street.toUpperCase()) {
            streetMatch.push(location);
          }
        }
        // console.log(exactMatch);
        // console.log(streetMatch);
        this.setState({
          exactMatch: exactMatch,
          streetMatch: streetMatch,
          loading: "",
          zipMatches: body.length,
        });

        // console.log(serviceArray);
      } else {
        this.setState({ locationsHTML: "Not Available for Selected Location" });
      }
    }
  };
  render() {
    var exactMatchCards = [];
    var streetMatchCards = [];

    for (var i = 0; i < this.state.exactMatch.length; i++) {
      exactMatchCards.push(
        <Col key={this.state.exactMatch[i]["_id"]}>
          <OnNetLocationsCard
            carrier={this.state.exactMatch[i]["Carrier"]}
            connectivityStatus={
              this.state.exactMatch[i]["Building Connectivity Status"]
            }
            lastUpdated={this.state.exactMatch[i]["Last Updated"]}
            accessType={this.state.exactMatch[i]["Access Type"]}
            street={this.state.exactMatch[i]["Street Address"]}
            city={this.state.exactMatch[i]["City"]}
            state={this.state.exactMatch[i]["State"]}
            zip={this.state.exactMatch[i]["Zip"]}
          />
        </Col>
      );
    }

    for (i = 0; i < this.state.streetMatch.length; i++) {
      streetMatchCards.push(
        <Col key={this.state.streetMatch[i]["_id"]}>
          <OnNetLocationsCard
            carrier={this.state.streetMatch[i]["Carrier"]}
            connectivityStatus={
              this.state.streetMatch[i]["Building Connectivity Status"]
            }
            lastUpdated={this.state.streetMatch[i]["Last Updated"]}
            accessType={this.state.streetMatch[i]["Access Type"]}
            street={this.state.streetMatch[i]["Street Address"]}
            city={this.state.streetMatch[i]["City"]}
            state={this.state.streetMatch[i]["State"]}
            zip={this.state.streetMatch[i]["Zip"]}
          />
        </Col>
      );
    }

    return (
      <div>
        <h2>On Net Locations</h2>
        <i>
          On Net Location data is provided by LincLogix partners and public
          information on carrier websites. It is not a comprehensive list of all
          carriers for all locations. Data freshness is listed with the results.
        </i>
        <hr />
        {this.state.loading}
        {/* <h3 hidden={this.state.zipMatches === 0}>
          Found <b>{this.state.zipMatches}</b> locations.
        </h3> */}
        <h3
          hidden={
            this.state.exactMatch === undefined ||
            this.state.exactMatch.length === 0
          }
        >
          Exact Matches
        </h3>
        <Row>{exactMatchCards}</Row>
        <h3
          hidden={
            this.state.streetMatch === undefined ||
            this.state.streetMatch.length === 0
          }
        >
          Street Matches
        </h3>
        <Row sm={1} md={2} lg={3}>
          {streetMatchCards}
        </Row>
        <h3
          hidden={
            !(
              this.state.streetMatch.length === 0 &&
              this.state.exactMatch.length === 0
            ) || this.state.zipMatches === 0
          }
        >
          No On Net or Near Net Locations Found
        </h3>
      </div>
    );
  }
}
