import React, { PureComponent } from "react";

// import Collapse from "react-bootstrap/Collapse";
import CardDeck from "react-bootstrap/CardDeck";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import Badge from "react-bootstrap/Badge";

import WiredCarrierCard from "./WiredCarrierCard";

import { GET_BROADBAND_SERVICE } from "../../constants/Constants";

class WiredCarriers extends PureComponent {
  compare(a, b) {
    var speedA = parseInt(a.maxaddown);
    var speedB = parseInt(b.maxaddown);

    if (speedA > speedB) {
      return 1;
    } else if (speedB > speedA) {
      return -1;
    } else {
      return 0;
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // this.setState({
    //   lat: props.lat,
    //   lon: props.lon,
    // });
    if (this.props !== prevProps) {
      this.setState({ serviceHTML: "Loading..." });
      if (this.props.lat != null && this.props.lon != null) {
        var serviceArray = await this.getBroadbandService(
          this.props.lat,
          this.props.lon
        );
        // console.log(serviceArray);
        var serviceHTML = await this.generateServiceHTML(serviceArray);
        this.setState({ serviceHTML: serviceHTML });
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      lat: props.lat,
      lon: props.lon,
      serviceHTML: "",
    };
    this.getBroadbandService = this.getBroadbandService.bind(this);
    this.compare = this.compare.bind(this);
    this.generateServiceHTML = this.generateServiceHTML.bind(this);
  }

  generateServiceHTML = (serviceArray) => {
    // 10=Asymetrical xDSL | 11=ADSL2 | 12=VDSL | 20=Symetrical xDSL | 30=Other Copper Wireline | 40=Cable Modem | 41=Cable Modem-DOCSIS1,1.1, and 2.0 | 42=Cable Modem-DOCSIS 3.0 | 43=Cable Modem-DOCSIS 3.1 | 50=Optical Carrier/Fiber to the End User | 60=Satellite | 70=Terrestrial Fixed Wireless | 90=Electric Power Line | 0=All Other

    // console.log(serviceArray);
    const inputs = [];
    for (let j = 0; j < serviceArray.length; j = j + 3) {
      // console.log(j);
      var temp = [];
      for (let i = j; i < j + 3 && i < serviceArray.length; i++) {
        // console.log(i);
        var technology = "";

        switch (serviceArray[i].techcode) {
          case "10":
            technology = "Asymetrical xDSL";
            break;
          case "11":
            technology = "ADSL2";
            break;
          case "12":
            technology = "VDSL";
            break;
          case "20":
            technology = "Symetrical xDSL";
            break;
          case "30":
            technology = "Other Copper Wireline";
            break;
          case "40":
            technology = "Cable Modem";
            break;
          case "41":
            technology = "Cable Modem-DOCSIS1,1.1, and 2.0";
            break;
          case "42":
            technology = "Cable Modem-DOCSIS 3.0";
            break;
          case "43":
            technology = "Cable Modem-DOCSIS 3.1";
            break;
          case "50":
            technology = "Optical Carrier/Fiber to the End User";
            break;
          case "60":
            technology = "Satellite";
            break;
          case "70":
            technology = "Terrestrial Fixed Wireless";
            break;
          case "90":
            technology = "Electric Power Line";
            break;
          default:
            technology = "Other - " + serviceArray[i].techcode;
        }

        var downSpeed = "";
        var upSpeed = "";
        var certified = false;
        downSpeed = serviceArray[i].maxaddown;
        upSpeed = serviceArray[i].maxadup;
        // if (serviceArray[i].maxcirdown === "0") {
          
        // } else {
        //   downSpeed = serviceArray[i].maxcirdown;
        //   certified = true;
        // }
        // if (serviceArray[i].maxcirup === "0") {
          
        // } else {
        //   upSpeed = serviceArray[i].maxcirup;
        //   certified = true;
        // }

        // console.log(technology);
        temp.push(
          <WiredCarrierCard
            key={i}
            dbaname={serviceArray[i].dbaname}
            providername={serviceArray[i].providername}
            business={serviceArray[i].business}
            consumer={serviceArray[i].consumer}
            technology={technology}
            downSpeed={downSpeed}
            upSpeed={upSpeed}
            certified={certified}
          />
        );
      }
      inputs.push(
        <div key={j * serviceArray.length}>
          <CardDeck>{temp}</CardDeck>
          <br />
        </div>
      );
    }

    // console.log(inputs);
    return inputs;
  };

  getBroadbandService = async (lat, lon) => {
    var request = GET_BROADBAND_SERVICE + "/" + lat + "/" + lon;

    var response = await fetch(request, { method: "GET" }).catch((err) => {
      console.error(err);
    });

    var body = await response.json();
    // console.log(body);
    return body;
    // this.setState({ broadbandService: body });
  };

  render() {
    return (
      <div>
        <h2>Wired Carriers</h2>
        <i>
          Wired Carrier data is pulled from the FCC National Broadband Database.
          This data is generated based upon the Census Code for the address
          provided. Every provider listed may not be available at the address
          provided.
        </i>
        <br />
        <b>Data pulled from 2019 FCC National Broadband Database V1</b>
        <hr />
        {/* <div>
          <p>
            <iframe title="Unite Private Network Map" src="https://carrier.upnfiber.com/UpnMap" width="100%" height="520"></iframe>
          </p>
      </div> */}
        {this.state.serviceHTML}
      </div>
    );
  }
}

export default WiredCarriers;
