import React, { Component } from "react";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";

var WEBSITE_MAP = new Map([
  [
    "Unite Private Networks",
    "https://uniteprivatenetworks.com/interactive-map/",
  ],
]);

export default class WiredCarrierCard extends Component {
  render() {
    var hasWebsite = false;
    if (WEBSITE_MAP.has(this.props.dbaname)) {
      hasWebsite = true;
    }
    return (
      <Card bg="dark">
        <Card.Body>
          <Card.Title>{this.props.dbaname}</Card.Title>
          <Card.Subtitle>
            <i>{this.props.providername}</i>
          </Card.Subtitle>
          {/* <hr /> */}
          <Badge
            style={{ minWidth: "50%" }}
            hidden={!(this.props.business === "1")}
            variant="primary"
          >
            Business
          </Badge>
          {/* {this.props.business === "1" ? " " : ""} */}
          <Badge
            style={{ minWidth: "50%" }}
            hidden={!(this.props.consumer === "1")}
            variant="secondary"
          >
            Consumer
          </Badge>
          <br />
          <b>Technology: </b> {this.props.technology}
          <br />
          <b>Speed: </b> {this.props.downSpeed} Mbps / {this.props.upSpeed} Mbps
          &nbsp;
          <Badge hidden={!this.props.certified} variant="success">
            Contractual
          </Badge>
          <br />
        </Card.Body>
        <Card.Footer hidden={!hasWebsite}>
          <Button
            style={{ minWidth: "100%" }}
            size="sm"
            target="_blank"
            rel="noopener noreferrer"
            href={WEBSITE_MAP.get(this.props.dbaname)}
            hidden={!hasWebsite}
          >
            <b>Carrier Website</b>
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}
