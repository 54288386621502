import React from "react";
import classes from "./App.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import SearchBar from "./components/SearchBar/SearchBar";
import Title from "./components/Title/Title";
import Footer from "./components/Footer/Footer";

function App() {
  // automatically redirect to HTTPS version of site if HTTP version is loaded
  if (window.location.protocol !== "https:") {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
  return (
    <div>
      <Title />
      <div className={classes.main}>
        <SearchBar />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
